<template>
  <h1 v-if="title" class="viewing-title">
    {{ title }}
  </h1>
</template>
<script>
/**
 * ==================================================================================
 * Viewing Title
 * ==================================================================================
 **/

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" scoped>
.viewing-title {
  text-transform: capitalize;
  line-height: 35px;

  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
