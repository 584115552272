<template>
  <v-avatar :size="size" :tile="tile" contain>
    <img :src="thumbSrc" alt="avatar" :key="imageKey" />
  </v-avatar>
</template>

<script>
export default {
  props: {
    avatar: {
      type: [String, Object, Function],
    },
    alt: {
      type: String,
      default: 'Avatar',
    },
    size: {
      type: String,
      default: '120',
    },
    tile: {
      type: Boolean,
    },
  },

  data() {
    return {
      imageKey: 0,
    }
  },

  computed: {
    thumbSrc() {
      return this.avatar && (this.avatar.thumb_url || this.avatar.url)
        ? this.avatar.thumb_url || this.avatar.url
        : this.avatar || require('@/assets/images/default-profile.png')
    },
  },

  watch: {
    avatar() {
      const interval = setInterval(() => {
        this.imageKey++
      }, 1000)

      setTimeout(() => {
        clearInterval(interval)
      }, 10000)
    },
  },
}
</script>

<style lang="scss" scoped>
.rounded-0 {
  border-radius: 5px !important;
}
</style>
