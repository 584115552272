<template>
  <div class="d-flex flex-row align-stretch mb-8 avatar-wrapper">
    <div>
      <v-skeleton-loader
        v-if="loading"
        width="160"
        height="160"
        type="card-avatar"
        tile
      ></v-skeleton-loader>
      <user-avatar v-else :avatar="avatar || tmpAvatar" tile size="160" />
    </div>

    <div
      class="d-flex flex-column justify-space-around ml-6"
      v-if="showUploadBtn"
    >
      <v-btn
        text
        class="text-capitalize font-weight-bold text-light-blue"
        @click="showCropperModal = !showCropperModal"
        :loading="loading"
        :disabled="disabled"
      >
        <img src="@/assets/icons/icon-image.svg" class="mr-2" alt />Upload Image
      </v-btn>
      <v-btn
        v-if="hasAvatar"
        text
        class="text-capitalize font-weight-bold text-light-grey"
        @click="deleteConfirmDialog = true"
        :loading="loading"
        :disabled="disabled"
      >
        <img src="@/assets/icons/icon-trash.svg" class="mr-2" alt /> Delete
        Image
      </v-btn>
    </div>

    <image-cropper
      v-model="showCropperModal"
      @change="uploadAvatar"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
    />

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Remove Image"
      message="Are you sure you want to remove the image?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteAvatar"
    ></ConfirmModal>
  </div>
</template>

<script>
import { mdiImagePlus, mdiDelete } from '@mdi/js'
import ImageCropper from '@/components/modals/ImageCropper'
import UserAvatar from '@/components/UserAvatar'
import User from '@/models/User'
import Contact from '@/models/Contact'
import Team from '@/models/Team'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import FileUploadMixin from '@/utils/mixins/FileUpload'

export default {
  components: {
    ImageCropper,
    UserAvatar,
    ConfirmModal,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin, FileUploadMixin],
  props: {
    user: {
      type: [User, Contact, Team],
    },

    upload: {
      type: Function,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
    },

    maxHeight: {
      type: Number,
    },

    showUploadBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        addImage: mdiImagePlus,
        delete: mdiDelete,
      },
      loading: false,
      showCropperModal: false,
      deleteConfirmDialog: false,
      tmpAvatar: undefined,
    }
  },
  computed: {
    avatar() {
      return this.hasAvatar ? this.uploadedAvatar : null
    },

    uploadedAvatar() {
      return this.user && this.user.avatar && this.user.avatar.url
        ? this.user.avatar
        : null
    },

    hasAvatar() {
      return !!this.uploadedAvatar
    },
  },
  methods: {
    async uploadAvatar(image) {
      if (this.loading) return
      let self = this
      const img = new Image()
      img.onload = function () {
        // if (this.width > self.maxWidth) {
        //   self.showSnackbar(
        //     `Ideal image size ${self.maxWidth}px x ${self.maxWidth}px`,
        //     false
        //   )
        // } else {
        self.loading = true

        let data = new FormData()
        data.append('id', self.user.id)
        data.append('avatar', image.file)
        data.append('avatar_url', image.url)

        self
          .upload(data)
          .then((data) => {
            self.$emit('change', data)

            self.showSnackbar('Avatar successfully updated!')
          })
          .catch((err) => {
            self.showSnackbar(self.getErrorMessage(err), false)
          })
          .finally(() => {
            self.loading = false
          })
        // }
      }
      img.src = image.url
    },
    async deleteAvatar() {
      if (this.user) {
        if (this.loading) return

        this.loading = true

        await this.$api
          .delete(`/${this.user.resource()}/${this.user.id}/avatar`)
          .then(() => {
            this.deleteConfirmDialog = false
            this.$emit('remove')

            this.showSnackbar('Avatar successfully deleted!')
          })
          .catch((err) => {
            this.showSnackbar(this.getErrorMessage(err), false)
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.tmpAvatar = undefined
      this.$emit('remove')
      this.showSnackbar('Avatar successfully deleted!')
    },
  },
  beforeDestroy() {
    this.tmpAvatar = undefined
  },
}
</script>
