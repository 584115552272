<template>
  <v-dialog
    :value="value"
    class="confirm-modal"
    @input="$emit('input')"
    max-width="290"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <strong>{{ message }}</strong>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <slot>
          <v-btn
            text
            :color="cancelColor"
            :loading="loading"
            class="confirm-modal__cancel"
            @click="$emit('cancel')"
            >{{ cancelText }}</v-btn
          >
          <v-btn
            text
            :color="confirmColor"
            :loading="loading"
            class="confirm-modal__confirm"
            @click="$emit('confirm')"
            >{{ confirmText }}</v-btn
          >
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,

    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },

    cancelColor: {
      type: String,
      default: 'red',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    confirmColor: {
      type: String,
      default: 'green',
    },

    confirmText: {
      type: String,
      default: 'Confirm',
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
